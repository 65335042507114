import { gql } from '@apollo/client';

import ApolloService from '../service';

const getAllQuery = gql`
  query GetAudios($filters: AudioFilterInput, $id: String!) {
    audios(filters: $filters) {
      id
      title
      authors {
        id
        name
      }
      genre {
        name
      }
      subgenre {
        name
      }
      conditions {
        name
      }
      tags {
        id
        name
      }
      featuredFromDate
      featuredToDate
      recommendedFromDate
      recommendedToDate
      expirationDate
      status
      isNew
      assignedToPackage(id: $id)
    }
    audioTotals {
      pageCount
      count
    }
  }
`;

const getQuery = gql`
  query GetAudio($id: String!) {
    audio(id: $id) {
      id
      title
      shortDescription
      authors {
        id
        name
      }
      genre {
        id
        name
        subgenres {
          id
          name
        }
      }
      subgenre {
        id
        name
      }
      conditions {
        id
        name
      }
      labels {
        id
        name
      }
      tags {
        id
        name
      }
      thumbnail {
        id
        url
      }
      audioUrl
      audioFile {
        id
        name
        url
      }
      featuredFromDate
      featuredToDate
      recommendedFromDate
      recommendedToDate
      recommendedImage {
        id
        url
      }
      recommendedDescription
      startDate
      expirationDate
      publisher
      publishedOn
      audioDuration
      status
      isNew
    }
  }
`;

const createMutation = gql`
  mutation CreateAudio($input: CreateAudioInput!) {
    createAudio(input: $input) {
      id
    }
  }
`;

const updateMutation = gql`
  mutation UpdateAudio($id: String!, $input: UpdateAudioInput!) {
    updateAudio(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteAudio($id: String!) {
    deleteAudio(id: $id) {
      id
    }
  }
`;

const exportMutation = gql`
  mutation ExportAudios($filters: AudioAdminFilterInput) {
    exportAudios(filters: $filters) {
      token
      link
    }
  }
`;

export default class AudioApolloService extends ApolloService {
  constructor(entity) {
    super(entity, {
      createMutation: createMutation,
      updateMutation: updateMutation,
      deleteMutation: deleteMutation,
      exportMutation: exportMutation,
      exportKey: 'exportAudios',
      getQuery: getQuery,
      getAllQuery: getAllQuery,
      getAllQueryResponseTransform: (data) => ({
        data: data.audios,
        total: data.audioTotals,
      }),
    });
  }
}
